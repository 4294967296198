import { get } from '@/services/http';
import List from './list';
import { DefaultTableVersionModel } from '@/models/default_table_version';

export class DefaultTableVersionList extends List<DefaultTableVersionModel> {
  public async fetch() {
    const res: any = await get(`/default_table_versions`, {});
    const defaultTableVersions = res.data || [];
    for (const s of defaultTableVersions) {
      const defaultTableVersion: DefaultTableVersionModel = new DefaultTableVersionModel();
      defaultTableVersion.mapData(s);
      this.add(defaultTableVersion);
    }
  }
}
