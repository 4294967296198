
import { Component, Vue } from 'vue-property-decorator';
import { DefaultTableVersionModel } from '@/models/default_table_version';
import { DefaultTableVersionList } from '@/collections/default_table_versions';

@Component({
  components: {
  },
})
export default class DefaultTableVersions extends Vue {
  public isLoading: boolean = true;
  public defaultTableVersions: DefaultTableVersionList = new DefaultTableVersionList();

  public created() {
    this.isLoading = true;
    this.fetchData();
    this.isLoading = false;
  }

  public async fetchData() {
    this.isLoading = true;
    this.defaultTableVersions = new DefaultTableVersionList();
    await this.defaultTableVersions.fetch();
    this.isLoading = false;
  }
}
